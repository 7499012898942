export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3991804772789231',
  agentpwd : '3991@9231$',
  apikey : '2350edaf908012ac979bdf6591c81977',
  url : 'https://wex.afrikpay.com',
  id : 'sofina.afrikpay.com',
  accepturl : 'https://wex.afrikpay.com/payment/web/success',
  cancelurl : 'https://wex.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};